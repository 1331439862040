<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Partograf</h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <br>
        <v-tabs
          class="mb-3"
          centered
          stacked>
          <v-tab @click="tabKanan = 0">PARTOGRAF</v-tab>
          <v-tab @click="tabKanan = 1">CATATAN PERSALINAN</v-tab>
        </v-tabs>
        <div :hidden="tabKanan !== 0" style="padding: 10px">
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form1"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form1[i] = e)"
                :value="data.form1[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div style="overflow-x: auto">
            <table class="table" border="1">
              <thead>
              <tr>
                <th class="text-center" colspan="33">Denyut Jantung Janin per Menit</th>
              </tr>
              <tr v-for="item in 13" :key="'a'+item">
                <th class="text-right p-1">{{(14-item)*10+70}}</th>
                <td class="p-1" v-bind:style="{ backgroundColor: data.form2[item]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 32" :key="'a'+item2">
                  <v-checkbox
                    v-model="data.form2[item]['p'+item2]"
                    label=" "
                    :value="data.form2[item]['p'+item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </td>
              </tr>
              <tr>
                <th colspan="33">&nbsp;</th>
              </tr>
              <tr v-for="(item,index) in data.form3" :key="'b'+index">
                <th>{{index === '1' ? 'Air Ketuban' : 'Penyusupan'}}</th>
                <td v-bind:style="{ backgroundColor: data.form3[index]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 32" :key="'a'+item2">
                  <v-checkbox
                    v-model="data.form3[index]['p'+item2]"
                    label=" "
                    :value="data.form3[index]['p'+item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </td>
              </tr>
              <tr>
                <th colspan="33">&nbsp;</th>
              </tr>
              <tr>
                <th class="text-center" colspan="33">Pembukaan serviks (cm) beri tanda</th>
              </tr>
              <tr v-for="item in 10" :key="'c'+item">
                <th class="text-right p-1">{{11-item}} cm</th>
                <td class="p-1" v-bind:style="{ backgroundColor: data.form4[item]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 32" :key="'c'+item2">
                  <v-checkbox
                    v-model="data.form4[item]['p'+item2]"
                    label=" "
                    :value="data.form4[item]['p'+item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </td>
              </tr>
              <tr>
                <th class="text-right">0</th>
                <th class="text-center" colspan="2" v-for="item in 16" :key="'jam'+item">{{item}}</th>
              </tr>
              <tr>
                <th class="text-right">Waktu (Jam)</th>
                <th class="text-center" colspan="2" v-for="item in 16" :key="'waktu'+item">
                  <v-text-field
                    v-model="data.form5['p'+item]"
                    label=" "
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr>
                <th colspan="33">&nbsp;</th>
              </tr>
              <tr>
                <th class="text-center" colspan="33">
                  <table>
                    <tr>
                      <th><div style="background-color: slateblue;width: 20px">&nbsp;</div></th>
                      <th> kecil dari 20 </th>
                      <th><div style="background-color: orange;width: 20px">&nbsp;</div></th>
                      <th>20-40</th>
                      <th><div style="background-color: green;width: 20px">&nbsp;</div></th>
                      <th> lebih dari 40 </th>
                    </tr>
                  </table>
                </th>
              </tr>
              <tr v-for="item in 5" :key="'e'+item">
                <th class="text-right p-1">{{6-item}}</th>
                <td class="p-1" v-bind:style="{ backgroundColor: data.form6[item]['p'+item2] === '' ? 'white' : (data.form6[item]['p'+item2] === '1' ? 'slateblue' : (data.form6[item]['p'+item2] === '2' ? 'orange' : 'green')) }" v-for="item2 in 32" :key="'e'+item2">
                  <button v-if="data.form6[item]['p'+item2] === ''" class="w-100" @click="data.form6[item]['p'+item2] = '1'">&nbsp;</button>
                  <button v-if="data.form6[item]['p'+item2] === '1'" class="w-100" @click="data.form6[item]['p'+item2] = '2'">&nbsp;</button>
                  <button v-if="data.form6[item]['p'+item2] === '2'" class="w-100" @click="data.form6[item]['p'+item2] = '3'">&nbsp;</button>
                  <button v-if="data.form6[item]['p'+item2] === '3'" class="w-100" @click="data.form6[item]['p'+item2] = ''">&nbsp;</button>
                </td>
              </tr>
              <tr>
                <th colspan="33">&nbsp;</th>
              </tr>
              <tr v-for="(item,index) in data.form7" :key="'bb'+index">
                <th class="p-1">{{index === '1' ? 'Oksitosin U/L' : 'Tetes / Menit'}}</th>
                <td class="p-1" v-bind:style="{ backgroundColor: data.form7[index]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 32" :key="'a'+item2">
                  <v-checkbox
                    v-model="data.form7[index]['p'+item2]"
                    label=" "
                    :value="data.form7[index]['p'+item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </td>
              </tr>
              <tr>
                <th colspan="33">&nbsp;</th>
              </tr>
              <tr>
                <th class="text-right">Obat dan Cairan IV</th>
                <th class="text-center" colspan="2" v-for="item in 16" :key="'obat'+item">
                  <v-text-field
                    v-model="data.form8['p'+item]"
                    label=" "
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr>
                <th class="text-center" colspan="33">Tekanan Darah</th>
              </tr>
              <tr v-for="item in 13" :key="'d'+item">
                <th class="text-right p-1">{{(14-item)*10+50}}</th>
                <td class="p-1" v-bind:style="{ backgroundColor: data.form9[item]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 32" :key="'d'+item2">
                  <v-checkbox
                    v-model="data.form9[item]['p'+item2]"
                    label=" "
                    :value="data.form9[item]['p'+item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </td>
              </tr>
              <tr>
                <th class="text-right">Suhu °C</th>
                <th class="text-center p-0" v-for="item in 32" :key="'suhu'+item">
                  <v-text-field
                    v-model="data.form10['p'+item]"
                    label=" "
                    dense
                    outlined
                    hide-details="auto"
                  />
                </th>
              </tr>
              <tr>
                <th colspan="33">&nbsp;</th>
              </tr>
              <tr v-for="(item,index) in data.form11" :key="'bbb'+index">
                <th class="p-1">{{index === '1' ? 'Urine\nProtein' : (index === "2" ? 'Urine\nAseton' : 'Urine\nVolume')}}</th>
                <td class="p-1" v-bind:style="{ backgroundColor: data.form11[index]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 32" :key="'a'+item2">
                  <v-checkbox
                    v-model="data.form11[index]['p'+item2]"
                    label=" "
                    :value="data.form11[index]['p'+item2]"
                    :class="`mt-0 pt-0 hidecheckbox`"
                    hide-details="auto"
                  />
                </td>
              </tr>
              </thead>
            </table>
          </div>
        </div>
        <div :hidden="tabKanan !== 1" style="padding: 10px">
          <v-row class="mt-0 mb-0">
            <v-col :md="6" :lg="6" :sm="6" cols="12">
              <v-row class="mt-0 mb-0">
                <v-col
                  v-for="(v, i) in master.form12"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-0 mb-0 pt-0 pb-3"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form12[i] = e)"
                    :value="data.form12[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :md="6" :lg="6" :sm="6" cols="12">
              <v-row class="mt-0 mb-0">
                <v-col
                  v-for="(v, i) in master.form13"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-0 mb-0 pt-0 pb-3"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.form13[i] = e)"
                    :value="data.form13[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br>
          <h6>PEMANTAUAN PERSALINAN KALA IV</h6>
          <table class="table" style="table-layout: fixed">
            <thead>
            <tr>
              <th>Jam Ke</th>
              <th>Waktu</th>
              <th>Tekanan Darah</th>
              <th>Nadi</th>
              <th>Suhu Tubuh</th>
              <th>Tinggi Fundus Uterus</th>
              <th>Kontraksi Uterus</th>
              <th>Kandung Kemih</th>
              <th>Perdarahan</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in 6" :key="item">
              <td v-if="item === 1" rowspan="4" class="text-center" style="vertical-align: middle">1</td>
              <td v-if="item === 5" rowspan="2" class="text-center" style="vertical-align: middle">2</td>
              <td v-for="item2 in 8" :key="item2">
                <v-text-field
                  v-model="data.form14[item]['p'+item2]"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/partograf'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      tabKanan: 0,
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {},
        form2: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          9: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          10: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          11: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          12: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          13: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form3: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form4: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          9: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          10: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form5: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: ''
        },
        form6: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form7: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form8: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: ''
        },
        form9: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          9: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          10: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          11: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          12: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          13: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form10: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: '',
          p8: '',
          p9: '',
          p10: '',
          p11: '',
          p12: '',
          p13: '',
          p14: '',
          p15: '',
          p16: '',
          p17: '',
          p18: '',
          p19: '',
          p20: '',
          p21: '',
          p22: '',
          p23: '',
          p24: '',
          p25: '',
          p26: '',
          p27: '',
          p28: '',
          p29: '',
          p30: '',
          p31: '',
          p32: ''
        },
        form11: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: ''
          }
        },
        form12: {},
        form13: {},
        form14: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: ''
          }
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>

.hidecheckbox{
  transform: scale(1.5);
  opacity: 0;
}
.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
