export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No. Register'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Nama ibu'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Tgl. Lahir'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 1,
      label: 'G'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 1,
      label: 'P'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 1,
      label: 'A'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 1,
      label: 'H'
    },
    p8: {
      widget: 'wdatenormal',
      data: null,
      col: 2,
      label: 'Tanggal'
    },
    p9: {
      widget: 'wtext',
      data: null,
      col: 1,
      label: 'Jam'
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Ketuban Pecah sejak jam'
    },
    p11: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Mules sejak jam'
    }
  },
  form12: {
    p1: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: '1. Tanggal'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '2. Nama Bidan'
    },
    p3: {
      widget: 'wradio',
      data: [
        { text: 'Rumah Ibu', value: 'Rumah Ibu' },
        { text: 'Puskesmas', value: 'Puskesmas' },
        { text: 'Polindes', value: 'Polindes' },
        { text: 'Rumah Sakit', value: 'Rumah Sakit' },
        { text: 'Klinik Swasta', value: 'Klinik Swasta' },
        { text: 'Lainnya', value: null, field: 'auto' }
      ],
      col: 6,
      label: '3. Tempat Persalinan'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '4. Alamat Tempat Persalinan'
    },
    p5: {
      widget: 'wcheckbox',
      data: [
        { text: 'Rujuk', value: 'Rujuk' }
      ],
      col: 6,
      label: '5. Catatan'
    },
    p5b: {
      widget: 'wradio',
      data: [
        { text: 'I', value: 'I' },
        { text: 'II', value: 'II' },
        { text: 'III', value: 'III' },
        { text: 'IV', value: 'IV' }
      ],
      col: 6,
      label: '5. Catatan (Kala)'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '6. Alasan Merujuk'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '7. Tempat Rujukan'
    },
    p8: {
      widget: 'wradio',
      data: [
        { text: 'Bidan', value: 'Bidan' },
        { text: 'Teman', value: 'Teman' },
        { text: 'Suami', value: 'Suami' },
        { text: 'Dukun', value: 'Dukun' },
        { text: 'Keluarga', value: 'Keluarga' },
        { text: 'Tidak Ada', value: 'Tidak Ada' }
      ],
      col: 6,
      label: '8. Pendamping Pada Saat Merujuk'
    },
    l1: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'KALA I'
    },
    p9: {
      widget: 'wtext',
      data: [
        { text: 'YA', value: 'YA' },
        { text: 'TIDAK', value: 'TIDAK' }
      ],
      col: 6,
      label: '9. Partograf melewati garis WASPADA'
    },
    p10: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '10. Masalah lain, sebutkan'
    },
    p11: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '11. Penatalaksanaan masalah tersebut'
    },
    p12: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '12. Hasilnya'
    },
    l2: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'KALA II'
    },
    p13: {
      widget: 'wradio',
      data: [
        { text: 'Ya, Indikasi', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '13. Episiotomi'
    },
    p14: {
      widget: 'wradio',
      data: [
        { text: 'Suami', value: 'Suami' },
        { text: 'Teman', value: 'Teman' },
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Keluarga', value: 'Keluarga' },
        { text: 'Dukun', value: 'Dukun' }
      ],
      col: 6,
      label: '14. Pendamping pada saat persalinan'
    },
    p15: {
      widget: 'wradio',
      data: [
        { text: 'Ya, tindakan yang dilakukan', value: 'Ya, tindakan yang dilakukan' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '15. Gawat Janin'
    },
    p15b: {
      widget: 'wradio',
      data: [
        { text: 'Ya, tindakan yang dilakukan', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '15. Gawat Janin'
    },
    p16: {
      widget: 'wradio',
      data: [
        { text: 'Ya, tindakan yang dilakukan', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '16. Distosia Bahu'
    },
    p17: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '17. Masalah Lain, sebutkan'
    },
    p18: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '18. Penatalaksanaan masalah tersebut'
    },
    p19: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '19. Hasilnya'
    },
    l3: {
      widget: 'wlabelkiri',
      data: [],
      col: 12,
      label: 'KALA III'
    },
    p20: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '20. Lama Kala III'
    },
    p21: {
      widget: 'wradio',
      data: [
        { text: 'Ya, waktu (Menit sesudah persalinan)', value: null, field: 'auto' },
        { text: 'Tidak, alasan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '21. Pemberian Oksitosin 10 U IM?'
    },
    p22: {
      widget: 'wradio',
      data: [
        { text: 'Ya, alasan', value: null, field: 'auto' },
        { text: 'Tidak, alasan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '22. Pemberian ulang Oksitosin (2x) ?'
    },
    p23: {
      widget: 'wradio',
      data: [
        { text: 'Ya, alasan', value: null, field: 'auto' },
        { text: 'Tidak, alasan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '23. Penegangan Tali Pusat Terkendali ?'
    }
  },
  form13: {
    p1: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak, alasan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '24. Masase fundus uteri?'
    },
    p2: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak, tindakan yang dilakukan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '25. Plasenta lahir lengkap (intactl)'
    },
    p3: {
      widget: 'wradio',
      data: [
        { text: 'Ya, Tindakan', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '26. Plasenta tidak lahir > 30 menit'
    },
    p4: {
      widget: 'wradio',
      data: [
        { text: 'Ya, dimana', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: '27. Laserasi'
    },
    p5: {
      widget: 'wradio',
      data: [
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' }
      ],
      col: 6,
      label: '28. Jika laserasi perineum, derajat'
    },
    p5b: {
      widget: 'wradio',
      data: [
        { text: 'Penjahitan, dengan / tanpa anestesi', value: 'Penjahitan, dengan / tanpa anestesi' },
        { text: 'Tidak, dijahit, alasan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '28. Tindakan (laserasi perineum, derajat)'
    },
    p6: {
      widget: 'wradio',
      data: [
        { text: 'Ya, tindakan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '29. Atonia uteri'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '30. Jumlah pendarahan',
      property: { attrs: { suffix: 'ml', type: 'number' } }
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '31. Masalah lain, sebutkan'
    },
    p9: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: '32. Penatalaksanaan masalah tersebut'
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '33. Hasilnya'
    },
    p11: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '34. Berat Badan',
      property: { attrs: { suffix: 'Gram', type: 'number' } }
    },
    p12: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '35. Panjang',
      property: { attrs: { suffix: 'cm', type: 'number' } }
    },
    p13: {
      widget: 'wradio',
      data: [
        { text: 'L', value: 'L' },
        { text: 'P', value: 'P' }
      ],
      col: 6,
      label: '36. Jenis Kelamin'
    },
    p14: {
      widget: 'wtext',
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Ada Penyulit', value: 'Ada Penyulit' }
      ],
      col: 6,
      label: '37. Penilaian bayi baru lahir'
    },
    p15: {
      widget: 'wradio',
      data: [
        { text: 'Normal, tindakan', value: null, field: 'auto' },
        { text: 'Asfiksia ringan / pucat / biru / lemas, tindakan', value: null, field: 'auto' },
        { text: 'Cacat Bawaan, Sebutkan', value: null, field: 'auto' },
        { text: 'Hipotermia, tindakan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '38. Bayi Lahir'
    },
    p16: {
      widget: 'wradio',
      data: [
        { text: 'Ya, waktu (jam setelah bayi lahir)', value: null, field: 'auto' },
        { text: 'Tidak, alasan', value: null, field: 'auto' }
      ],
      col: 6,
      label: '39. Pemberian ASI'
    },
    p17: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '40. Masalah lain, sebutkan'
    },
    p17b: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: '40. Masalah lain (Hasilnya)'
    }
  }
}
